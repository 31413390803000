<div class="phone">
  <div (click)="onOpen()" class="country">
    <img (click)="onOpen()" class="country__img" [src]="'/assets/svg/flags/' + currentCountry + '.svg'" alt="country - spinpanda casino">
    <div class="country__label">{{ currentCountry }}</div>
    <i class="icon-pointer-down"></i>
  </div>
  <div class="countries" [ngClass]="{open: isOpen}" ClickOutside (clickOutside)="onClose()">
    <input class="countries__search" [formControl]="search" type="text" [placeholder]="'t.search' | translate">
    @if (filteredCountryList$ | async; as filteredCountryList) {
      <div class="countries__list">
        @for (country of filteredCountryList; track country) {
          <div
            (click)="onSelect(country)"
            [ngClass]="{'active': currentCountry === country.short}"
            class="countries__list--item"
            >
            <img [src]="'/assets/svg/flags/' + country?.short + '.svg'" alt="flag - spinpanda casino">
            <span>{{ country?.name }}</span>
          </div>
        }
      </div>
    }
  </div>

  <input
    [prefix]="userPhone?.prefix"
    [mask]="userPhone.mask?.mask"
    [showMaskTyped]="true"
    [dropSpecialCharacters]="true"
    [validation]="showError"
    [(ngModel)]="value"
    #inputModel="ngModel"
    type="text"
    class="input input--simple phone-input"
    >
  </div>
