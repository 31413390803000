import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { SsApiService } from '../api/ss-api.service';
import { catchError, filter, finalize, first, map, share, switchMap, tap } from 'rxjs/operators';
import { CommonDataService, isCryptoAcc, NOT_NEEDED_CURRENCIES } from '../common-data.service';
import { UserInfoService } from './user-info.service';
import { CmsContentMapperService } from '../cms-content-mapper.service';
import { CmsApiService } from '../api/cms-api.service';
import { CookieService, LocalstorageService, PlatformService } from 'ngx-unificator/services';
import { MhlApiService } from '../api/mhl-api.service';
import { Router } from '@angular/router';
import { LoyaltyGroups } from '../../loyalty.data';


@Injectable({
  providedIn: 'root',
})
export class UserService {

  /**
   * Is user authorized
   */
  private _auth: boolean;
  private _auth$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private _auth$$: Observable<any> = this._auth$.asObservable();

  /**
   * Player's additional data
   */
  private _playerStats: any = {};
  private _playerStats$: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _playerStats$$: Observable<any> = this._playerStats$.asObservable();

  /**
   * Emits when user currency changed
   */
  private _currency$: ReplaySubject<string> = new ReplaySubject<string>(1);
  private _currency$$: Observable<string> = this._currency$.asObservable();

  /**
   * User info
   */
  private _info: any = {};

  /**
   * User`s active currency (account)
   */
  private _currentCurrency: any;

  private _currentCurrencyCompatibility: any;

  /**
   * List of user accounts (currencies)
   */
  private _accountList: Array<any> = [];

  /**
   * Emits list of user accounts (currencies)
   */
  private _accountList$: ReplaySubject<any> = new ReplaySubject<any>(1);

  private _accountListCompatibility: Array<any> = [];

  /**
   * Has user already been registered before (by cookie)
   */
  private _isUserAlreadyRegistered: boolean;

  /**
   * Check if user allready registered and return dymanic translate key
   */
  public authKeyTranslate: string;

  private _loyaltyActiveLevel: string;

  constructor(
    private _ssApi: SsApiService,
    private _cmsApi: CmsApiService,
    private _commonData: CommonDataService,
    private _userInfo: UserInfoService,
    private _contentMapper: CmsContentMapperService,
    private _platform: PlatformService,
    private _mlhApi: MhlApiService,
    private _storage: LocalstorageService,
    private _cookies: CookieService,
    private _router: Router,
  ) {
    /**
     * For avoiding circular dependency
     */
    this._userInfo.userService = this;
    this._contentMapper.user = this;
    this._checkUserAlreadyRegistered();
    this.fetchAllUserData();
    this.authKeyTranslate = this.isUserAlreadyRegistered ? 't.sign-in' : 't.sign-up';

    console.log(`User ser inited`);
  }

  /**
   * Access to private fields from outside
   */
  get auth(): boolean {
    return this._auth;
  }

  get auth$(): Observable<boolean> {
    return this._auth$$;
  }

  get info(): any {
    return this._info;
  }

  get currentCurrency(): any {
    return this._currentCurrency;
  }

  get currentCurrencyCompatibility(): any {
    return this._currentCurrencyCompatibility;
  }

  get accountList(): Array<any> {
    return this._accountList;
  }

  get stats(): Array<any> {
    return this._playerStats;
  }

  get stats$(): Observable<any> {
    return this._playerStats$$;
  }

  get hasDeposit(): boolean {
    return !!this._playerStats.deposits_count;
  }

  get depositCount(): number {
    return this._playerStats?.deposits_count;
  }

  get currency$(): Observable<string> {
    return this._currency$$;
  }

  get accountList$(): ReplaySubject<any> {
    return this._accountList$;
  }

  get accountListCompatibility(): Array<any> {
    return this._accountListCompatibility;
  }

  /**
   * Access to _isUserAlreadyRegistered
   */
  get isUserAlreadyRegistered(): boolean {
    return this._isUserAlreadyRegistered;
  }

  get loyaltyActiveLevel() {
    return this._loyaltyActiveLevel;
  }

  /**
   * Get user account list and save to local var
   */
  getUserAccounts(): Observable<any> {
    return this._ssApi.playerAccounts().pipe(
      map(accountList => accountList.filter(account => !NOT_NEEDED_CURRENCIES.includes(account.currency))),
      map(accountList => this._serializeCurrencyValues(accountList)),
      catchError(() => of([])),
      tap(accountList => {
        this._accountList = accountList;
        this._accountList$.next(accountList);
        this._updateCurrentCurrencyBalance();
      }),
    );
  }

  /**
   * Get user account compatibility list and save to local var
   */
  getUserAccountsCompatibility(): Observable<any> {
    return this._ssApi.playerAccounts({ compatibility: false }).pipe(
      map(accountList => accountList.filter(account => !NOT_NEEDED_CURRENCIES.includes(account.currency))),
      map(accountList => this._serializeCurrencyValues(accountList)),
      catchError(() => of([])),
      tap(accountList => {
        this._accountListCompatibility = accountList;
        this._currentCurrencyCompatibility = this._accountListCompatibility.find(account => account.currency === this._info.currency) || {};
      }),
    );
  }

  /**
   * Get user info and save to local var
   */
  getUserInfo(): Observable<any> {
    this._waitingForPlayerRequest();
    return this._ssApi.player().pipe(
      catchError(() => of({})),
      tap(info => {
        this.applyUserInfo(info);
      }),
    );
  }

  /**
   * Get additional player`s data from backend
   */
  getPlayerStats(): Observable<any> {
    return this._ssApi.playerStats().pipe(
      catchError(() => of({})),
      tap(stats => {
        this._playerStats = stats;
        this._playerStats$.next(stats);
      }),
    );
  }

  /**
   * Save user info to local var and declare user state
   *
   * @param info
   * @param isNewFormVersion
   */
  applyUserInfo(info: any, isNewFormVersion?: boolean) {
    this._info = {
      ...info,
      gender_name: info.gender === 'm' ? 't.male' : info.gender === 'f' ? 't.female' : '',
      country_name: this._commonData.countryName(info.country || ''),
    };

    if ('id' in info) {
      this._storage.set('user_id', info.id);
      this._cmsApi.customHeadersList.push({ key: 'UID', val: `${info.id}` });
      this._mlhApi.customHeadersList.push({ key: 'UID', val: `${info.id}` });
      if (info.country) {
        this._cmsApi.customHeadersList.push({ key: 'UC', val: info.country });
        this._cmsApi.customNoLangHeadersList.push({ key: 'UC', val: info.country });
        this._mlhApi.customHeadersList.push({ key: 'UC', val: info.country });
        this._mlhApi.customHeadersList.push({ key: 'UID', val: `${info.id}` });
      }

      if (info.statuses && info.statuses.length) {
        const uGroup = {
          key: 'U-GROUP',
          val: info.statuses.map(status => status.id).join(','),
        };
        this._cmsApi.customHeadersList.push(uGroup);
        this._cmsApi.customNoLangHeadersList.push(uGroup);
      }

      this._auth = !isNewFormVersion ? true : false;
      this._auth$.next(this._auth);
      this._setAlreadyRegisteredCookie();
      this._userInfo.checkMissingAuthFields(info);

      const loyaltyActiveLevel = Object.values(LoyaltyGroups).reverse().find(level => {
        return info.statuses.find(status => level?.ids.includes(status.id));
      });

      if (loyaltyActiveLevel) {
        this._loyaltyActiveLevel = loyaltyActiveLevel.BOidentifier;
      }
    } else {
      this._auth = false;
      this._auth$.next(false);
    }
  }

  /**
   * Fetch all required user data
   *
   * @private
   */
  public fetchAllUserData() {
    const observable = this._commonData.loaded$.pipe(
      first(),
      switchMap(() => this.getUserInfo()),
      switchMap(() => this.getPlayerStats()),
      switchMap(() => this.getUserAccounts()),
      switchMap(() => this.getUserAccountsCompatibility()),
      share(),
    );

    observable.subscribe();

    return observable;
  }

  /**
   * Update current currency balance info
   *
   * @private
   */
  private _updateCurrentCurrencyBalance() {
    this._currentCurrency = this._accountList.find(account => account.currency === this._info.currency) || {};
    this._currency$.next(this._currentCurrency.currency);
  }

  /**
   * Change user currency account
   * @param selectedCurrency
   */
  public changeCurrencyAcc(selectedCurrency: string) {
    if (selectedCurrency) {
      this._currency$.next(null);
      this._ssApi.postPlayerAccounts({ currency: selectedCurrency }).subscribe((e) => {
        this.fetchAllUserData();
      });
    }
  }


  /**
   * Serialize currency values
   * @param accountList
   * @private
   */
  private _serializeCurrencyValues(accountList: any[]) {
    return accountList.map(account => {
      return {
        ...account,
        amount: this._commonData.subunitsToUnits(account.amount_cents, account.currency),
        cashout: this._commonData.subunitsToUnits(account.available_to_cashout_cents, account.currency),
        symbol: this._commonData.currencySymbol(account.currency),
      };
    });
  }

  /**
   * User logout
   */
  logout() {
    this._ssApi.usersSignOut().pipe(
      filter(() => this._platform.isBrowser),
      finalize(() => {
        window.location.href = '/';
      }),
    ).subscribe();
  }

  public isHasCryptoAccounts() {
    return this.accountList.some(account => isCryptoAcc(account.currency));
  }


  /**
   * Waiting for /player response 2 seconds and emit default value
   * @private
   */
  private _waitingForPlayerRequest() {
    if (this._platform.isBrowser) {
      setTimeout(() => {
        if (this._auth === undefined) {
          this.applyUserInfo({ language: 'en' });
        }
      }, 2000);
    }
  }

  private _setAlreadyRegisteredCookie() {
    if (!this.isUserAlreadyRegistered && this._platform.isBrowser) {
      this._cookies.set('registered', '1', 999, '/', (window.location.hostname as any));
    }
  }

  /**
   * Check in cookie has user already been registered
   */
  private _checkUserAlreadyRegistered() {
    this._isUserAlreadyRegistered = this._cookies.check('registered');
  }

  /**
   * Check if user allready registered and redirect
   */
  public authUser() {
    return this._router.navigateByUrl(this.isUserAlreadyRegistered ? '/login' : '/register');
  }
}
