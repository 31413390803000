import { StoreEntityType } from 'ngx-unificator/store';
import { Observable, of } from 'rxjs';

export interface IValentineState {
  page$: Observable<IValentinePage>;
  currentBonus: ICurrentBonusItem;
  currentPrize: ICurrentPrizeItem;
  isUserCanParticipateInPromo: boolean;
  isUserCanPartiallyParticipateInPromo: boolean;
  isUserAlreadyActivatedPromoLootbox: boolean;
  isSwipeBonus: boolean;
  weeklyCompletedBonusesCount: number;
  weeklyCompletedBonusesLimit: number;
  weeklyHeartBonuses: IHeartWeeklyBonuses[];
  isWeeklyMissionsCompleted: boolean;
  isLoadedPromo: boolean;
  spinderCards: { img: string }[];
  valentineBonusStr: string;
}

export interface IHeartWeeklyBonuses {
  default: boolean;
  active: boolean;
}

export interface IValentinePage {
  Title: string;
  Description: string;
  Prize: string;
  PrizeAuth: string;
  PrizeDescription: string;
  HowWorksTitle: string;
  HowWorksSteps: { title: string, description: string }[];
  WeeklyBonusLabelTitle: string;
  WeeklyBonusTitle: string;
  WeeklyBonusDescription: string;
  GamesTitle: string;
  GamesDescription: string;
  TournamentsTitle: string;
  HowWorksTerms: string;
  GeneralTerms: string;
  SpinderActionTitle: string;
  MatchTitle: string;
  TryTomorrowTitle: string;
  VisitAccountTitle: string;
  VisitAccountWeeklyBonusTitle: string;
  WeeklyBonusCatchTitle: string;
  WeeklyBonusCatchDescription: string;
  WeeklyBonusCatchRule: string;
  WeeklyPrizeAmount: string;
  unpublishAt: any;
  publishAt: any;
}

export interface ICurrentBonusItem {
  fs?: any;
  cash?: any;
  welcomeLootbox?: any;
  promoLootbox?: any;
}

export interface ICurrentPrizeItem {
  fs_count?: number;
  cash?: any;
}

export const valentineStore: StoreEntityType<IValentineState> = {
  page$: of(null),
  currentBonus: null,
  currentPrize: null,
  isUserCanParticipateInPromo: false,
  isUserCanPartiallyParticipateInPromo: false,
  isUserAlreadyActivatedPromoLootbox: false,
  isSwipeBonus: false,
  weeklyCompletedBonusesCount: 0,
  weeklyCompletedBonusesLimit: 5,
  weeklyHeartBonuses: [
    { default: true, active: false },
    { default: true, active: false },
    { default: true, active: false },
    { default: true, active: false },
    { default: true, active: false },
    { default: true, active: false },
  ],
  isWeeklyMissionsCompleted: false,
  isLoadedPromo: false,
  spinderCards: [],
  valentineBonusStr: 'spinder'
};

export type ValentineStoreEntity = typeof valentineStore;
