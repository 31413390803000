import { Injectable } from '@angular/core';
import {
  CUSTOM_ROUTES,
  methodsWithOwnImage,
  methodsWithOwnImageByIds,
  redirectRoutes,
  SS_LIB_CONFIG,
} from './ss-payment-config';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { LibError, Payment, PaymentsLibType, PaymentsMethod, Results } from './ss-payment-types';
import { environment } from 'src/environments/environment';
import { WindowService } from '../../services/window.service';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { ToastMessageService } from '../../shared/modules/toast-message/toast-message.service';
import { FormsErrorHandlerService } from '../../services/forms-error-handler.service';
import { ArrayService, PlatformService } from 'ngx-unificator/services';
import { ignoreHosts } from '../../../../config/ignore.hosts';
import { RedirectRoutes } from 'payments-lib-types';
import { Router } from '@angular/router';

declare var PaymentsAPI: PaymentsLibType;

/**
 * The function `interceptPaymentSavedAccounts` intercepts XMLHttpRequests to a specific API endpoint
 * and extracts saved account information to pass to a callback function.
 * @param {CallableFunction} callback - The `callback` parameter in the `interceptPaymentSavedAccounts`
 * function is a function that will be called with the list of saved accounts retrieved from the
 * response when a payment request is made.
 * @returns The `originalSend.apply(this, arguments)` statement is being returned in the
 * `interceptPaymentSavedAccounts` function.
 */
const interceptPaymentSavedAccounts = (callback: CallableFunction) => {
  const originalSend = XMLHttpRequest.prototype.send;
  XMLHttpRequest.prototype.send = function(body) {
    this.addEventListener('readystatechange', function() {
      if (this.readyState === 4 && this.responseURL.includes('https://api-paymentiq-io.softswiss.net')) {
        const savedAccList = this.response?.methods?.map(e => e?.accounts)?.flat() || [];
        callback(savedAccList);
      }
    });
    return originalSend.apply(this, arguments);
  };
};

@Injectable({ providedIn: 'root' })
export class SsPaymentsV2Service {
  /**
   * Is ready to use source
   */
  private _ready$: ReplaySubject<boolean> = new ReplaySubject(1);

  private _savedAccMap = new Map<string, any>();

  public paymentInProgress = false;

  public CustomDepositRoutes: RedirectRoutes = {
    successUrl: this._platform.isBrowser ? `${location.origin}/payment-status/deposit/success` : '/payment-status/deposit/success',
    failureUrl: this._platform.isBrowser ? `${location.origin}/payment-status/deposit/failure` : '/payment-status/deposit/failure',
    supportUrl: this._platform.isBrowser ? `${location.origin}/faq` : '/faq',
    pendingUrl: this._platform.isBrowser ? `${location.origin}/profile/history` : '/profile/history',
  };

  constructor(
    private _platform: PlatformService,
    private _window: WindowService,
    private _toastMessage: ToastMessageService,
    private _formErrors: FormsErrorHandlerService,
    private _array: ArrayService,
    private _router: Router,
  ) {
  }

  /**
   * Load api library script
   */
  loadApiLibrary() {
    this._initApiLibraryConfig();
  }

  /**
   * Set Library config & lazy load second library part
   */
  private async _initApiLibraryConfig() {
    SS_LIB_CONFIG.serverUrl = this._resolveHost();
    PaymentsAPI?.config(SS_LIB_CONFIG)?.then(() => {
      if (PaymentsAPI._isLoaded && this._platform.isBrowser) {
        interceptPaymentSavedAccounts(savedAccList => {
          this._resolveSavedAccMap(savedAccList);
        });
        this._ready$.next(true);
      }
    });
  }

  /**
   * Fetch payment methods list by currency & action
   * @param currency
   * @param action
   */
  public fetchMethods(currency: string, action: Payment.Action): Observable<PaymentsMethod[]> {
    return this._ready$.pipe(
      first(),
      switchMap(() => fromPromise(PaymentsAPI.getMethods({ currency, paymentAction: action }))),
      map((list: PaymentsMethod[]) =>
        list.map((method: any) => {
          method.currency = currency;
          method.brand = method.id.split('_')[method.id.split('_').length - 1];
          method.provider = method.id.split('_')[0];
          setTimeout(() => {
            method.savedProfiles = method?.savedProfiles?.map((acc: any) => {
              if (this._savedAccMap.has(acc?.id)) {
                const cardHolder = this._savedAccMap.get(acc?.id)?.cardHolder || null;
                const expiryDate = this._savedAccMap.get(acc?.id)?.expiryDate || null;
                acc.cardHolder = cardHolder;
                acc.expiryDate = expiryDate;
              }
              return acc;
            });
          }, 100);
          return method;
        }),
      ),
      map((list: PaymentsMethod[]) => list.map(method => ({
        ...method,
        ourImg: this._resolveOurImage(method),
      }))),
    );
  }

  /**
   * Get fields for current method
   * @param id
   * @param currency
   * @param paymentAction
   * @param savedProfileId
   * @returns
   */
  public getCurrentMethodFields(id: any, currency: string, paymentAction: Payment.Action, savedProfileId: string = '') {
    return this._ready$.pipe(
      first(),
      switchMap(() =>
        fromPromise(
          PaymentsAPI.getMethodFields({
            id,
            currency,
            paymentAction,
            savedProfileId,
          }),
        ),
      ),
      map(method => this._resolveMethodFields(method)),
    );
  }

  /**
   * Resets the cache for the Payments API.
   * @returns An observable that completes when the cache reset is complete.
   */
  public resetCache() {
    return fromPromise(PaymentsAPI.resetCache());
  }

  /**
   * Retrieves the available conversion methods for the specified currency and payment action.
   * @param currency - The currency for which to retrieve the conversion methods.
   * @param paymentAction - The payment action for which to retrieve the conversion methods.
   * @returns An observable that emits the available conversion methods.
   */
  public getConversionMethods(currency: string, paymentAction: Payment.Action) {
    return this._ready$.pipe(
      first(),
      switchMap(() =>
        fromPromise(
          PaymentsAPI.getConversionMethods({
            currency,
            paymentAction,
          }),
        ),
      ),
    );
  }

  /**
   * Resolve current method fields, add translate key
   * @param method
   * @returns
   */
  private _resolveMethodFields(method: Results.GetMethodFieldsResult) {
    if (method.amountField) {
      method.amountField.translationKey = 'labl.' + method.amountField.translationKey;
    }
    method.methodFields = method.methodFields.map(e => {
      e.translationKey = 'labl.' + e.translationKey;
      return e;
    });
    method.playerFields = method.playerFields.map((e: any) => {
      e.fieldName = e.field;
      e.translationKey = 't.' + e?.field?.replace('_', '-');
      return e;
    }) as any[];
    return method;
  }

  /**
   * Deletes user's saved profile for the specific method
   * @param id
   * @param currency
   * @param paymentAction
   * @param savedProfileId
   * @returns
   */
  public deleteSavedAcc(id: any, currency: string, paymentAction: Payment.Action, savedProfileId: string) {
    return this._ready$.pipe(
      first(),
      switchMap(() =>
        fromPromise(
          PaymentsAPI.deleteSavedProfile({
            id,
            currency,
            paymentAction,
            savedProfileId,
          }),
        ),
      ),
    );
  }

  /**
   * Responsible for processing the transaction, redirecting to the payment system, and opening a modal for additional data
   * @param id
   * @param currency
   * @param paymentAction
   * @param paymentType
   * @param savedProfileId
   * @param methodFieldsData
   * @param playerFieldsData
   * @returns
   */
  public submitForm(
    id: string,
    currency: string,
    paymentAction: Payment.Action,
    methodFieldsData,
    savedProfileId: string = '',
    playerFieldsData = null,
    customRedirectRoutes?: RedirectRoutes,
  ) {
    SS_LIB_CONFIG['redirectRoutes'] = {
      ...(customRedirectRoutes || redirectRoutes(paymentAction)),
      ...Object.keys(CUSTOM_ROUTES).reduce((acc, key) => {
        if (CUSTOM_ROUTES[key]) {
          acc[key] = CUSTOM_ROUTES[key];
        }
        return acc;
      }, {}),
    };
    return this._ready$.pipe(
      first(),
      switchMap(() => fromPromise(PaymentsAPI.config(SS_LIB_CONFIG))),
      switchMap(() => fromPromise(PaymentsAPI.submit({
        id,
        currency,
        paymentAction,
        amountValue: methodFieldsData.amount,
        savedProfileId,
        methodFieldsData,
        playerFieldsData,
      } as any))),
    );
  }

  /**
   * Resolve payment host for mirrors
   * @returns
   */
  private _resolveHost() {
    return this._platform.isBrowser && !ignoreHosts.some(item => this._window.nativeWindow?.location?.host?.includes(item))
      ? this._window.nativeWindow.location.origin.replace('https://www.', 'https://')
      : environment.ss_host;
  }

  /**
   * The function `_resolveSavedAccMap` populates a map with account objects from a list if the account
   * ID is not already present in the map.
   * @param {any[]} savedAccList - The `savedAccList` parameter is an array of objects representing saved
   * accounts.
   */
  private _resolveSavedAccMap(savedAccList: any[]) {
    savedAccList.forEach(acc => {
      if (!this._savedAccMap.has(acc?.accountId)) {
        this._savedAccMap.set(acc?.accountId, acc);
      }
    });
  }

  /**
   * Submits a payment form with the provided payment data, payment method, and action.
   * @param paymentData - The payment data object containing the form data.
   * @param method - The payment method object containing the method ID and currency.
   * @param action - The payment action (e.g. Cashout).
   * @returns An observable that emits the payment submission result.
   */
  public submitPaymentForm(paymentData) {
    const customRoutes = this._platform.isBrowser && this._router.url.includes('/play/')
      ? this.CustomDepositRoutes
      : null;
    this.paymentInProgress = true;
    this.submitForm(
      paymentData?.method?.id,
      paymentData?.method?.currency,
      paymentData?.action,
      paymentData.data,
      paymentData?.savedProfileId?.id || null,
      paymentData?.playerFieldsData,
      customRoutes,
    ).pipe(
      catchError((error: LibError.SubmitError) => {
        console.log(error);
        this._resolvePaymentFieldErrors(paymentData, error);
        return of(error);
      }),
      tap(e => this._resolvePaymentMessage(e, paymentData?.action)),
      finalize(() => {
        this.paymentInProgress = false;
      }),
    ).subscribe();
  }

  /**
   * Resolves the payment message based on the provided error object and action.
   * @param e - The payment error object.
   * @param action - The payment action (e.g. Cashout).
   */
  private _resolvePaymentMessage(e, action) {
    console.log(e);
    if (e?.status === 'notify') {
      if (action === Payment.Action.Cashout) {
        this._toastMessage.info('t.cashout-request-received');
      }
    }
  }

  /**
   * Resolves payment field errors by displaying toast messages and applying form errors.
   * @param paymentData - The payment data object containing the form.
   * @param error - The payment submission error object.
   */
  private _resolvePaymentFieldErrors(paymentData, error: LibError.SubmitError) {
    if (error.type === LibError.Type.Params || error.type === LibError.Type.Request) {
      if (error.commonErrors?.length || error?.methodFieldsErrors || error?.playerFieldsErrors || error.amountFieldErrors) {
        this._array.fromObjectValues(error.commonErrors || error.methodFieldsErrors || error?.playerFieldsErrors || error.amountFieldErrors)
          .forEach(currentError => this._toastMessage.error(currentError));
        const fieldErrors = {
          ...(error?.methodFieldsErrors || {}),
          ...(error?.playerFieldsErrors || {}),
          ...(error?.amountFieldErrors || {}),
        };
        this._formErrors.applyFormErrors(paymentData.form, { errors: { ...fieldErrors } }, true);
      }
    }
  }

  /**
   * Returns payment method logo URL
   * @param method
   * @private
   */
  private _resolveOurImage(method: PaymentsMethod): string {
    if (methodsWithOwnImage.includes(method.brand)) {
      return `/assets/svg/payment-methods/color/${method.brand}.svg`;
    }

    const foundSubstring = methodsWithOwnImageByIds.find(substring => method.id.includes(substring));
    if (foundSubstring) {
      return `/assets/svg/payment-methods-new/${foundSubstring}.svg`;
    }

    return null;
  }
}
