import { Injectable } from '@angular/core';
import { TranslationService } from '../../translation/translation.service';

enum ToastMessageType {
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export interface ToastMessage {
  type: ToastMessageType;
  content: any;
  hasComponent: boolean;
  isExpirable: boolean;
  isClicked: boolean;
  callback: any;
  hideAfterClick?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ToastMessageService {
  /**
   * Time before message deletes
   */
  private _expirationTime = 5 * 1000;

  constructor(private _translate: TranslationService) {
  }

  /**
   * List of visible messages
   */
  private _messagesList: Array<ToastMessage> = [];

  get messagesList(): Array<ToastMessage> {
    return this._messagesList;
  }

  /**
   * Show ERROR toast message
   *
   * @param content
   * @param translateParams
   * @param hasComponent
   * @param isExpirable
   */
  public error(content: any, translateParams = null, hasComponent = false, isExpirable = true) {
    return this._showMessage(ToastMessageType.ERROR, content, translateParams, hasComponent, isExpirable);
  }

  /**
   * Show INFO toast message
   *
   * @param content
   * @param translateParams
   * @param hasComponent
   * @param isExpirable
   */
  public info(content: any, translateParams = null, hasComponent = false, isExpirable = true, hideAfterClick = true) {
    return this._showMessage(ToastMessageType.INFO, content, translateParams, hasComponent, isExpirable, hideAfterClick);
  }

  /**
   * Show WARNING toast message
   *
   * @param content
   * @param translateParams
   * @param hasComponent
   * @param isExpirable
   */
  public warning(content: any, translateParams = null, hasComponent = false, isExpirable = true) {
    return this._showMessage(ToastMessageType.WARNING, content, translateParams, hasComponent, isExpirable);
  }

  /**
   * Show SUCCESS toast message
   *
   * @param content
   * @param translateParams
   * @param hasComponent
   * @param isExpirable
   */
  public success(content: any, translateParams = null, hasComponent = false, isExpirable = true) {
    return this._showMessage(ToastMessageType.SUCCESS, content, translateParams, hasComponent, isExpirable);
  }

  public removeMessage(message: any) {
    message.callback();
    const index = this.messagesList.indexOf(message);
    if (index !== -1) {
      this._messagesList.splice(index, 1);
    }
  }

  /**
   * Showing toast message
   *
   * @param type
   * @param content
   * @param translateParams
   * @param hasComponent
   * @param isExpirable
   * @param hideAfterClick
   * @private
   */
  private _showMessage(
    type: ToastMessageType,
    content: any,
    translateParams: any,
    hasComponent: any,
    isExpirable: any,
    hideAfterClick?: boolean,
  ) {
    const message: ToastMessage = {
      type,
      content: this._setContent(content, translateParams),
      hasComponent,
      isExpirable,
      hideAfterClick,
      isClicked: false,
      callback: () => {
      },
    };

    this._messagesList.push(message);

    /**
     * Removing created message after time expires
     */
    if (isExpirable) {
      setTimeout(() => {
        this.removeMessage(message);
      }, this._expirationTime);
    }

    return message;
  }

  /**
   * Sets content for toast message
   */
  private _setContent(content: any, translateParams: any) {
    if (typeof content === 'string') {
      return this._translate.translate(content, translateParams);
    } else {
      return content;
    }
  }
}
