import { Validators } from '@angular/forms';
import { ValidationPatterns } from './validation-patterns';
import { CustomValidators } from 'ngx-unificator/helpers';

export enum FieldType {
  TEXT = 'text',
  EMAIL = 'email',
  PASSWORD = 'password',
  SELECT = 'select',
  CHECKBOX = 'checkbox',
  DATE = 'date',
  PHONE = 'phone',
  RADIO = 'radio',
  NUMBER = 'number',
  STRING = 'string',
  COUNTRY = 'country',
}

export const GlobalFieldsConfig = {
  email: {
    label: 'labl.email',
    type: FieldType.EMAIL,
    validator: [Validators.required, Validators.pattern(ValidationPatterns.email)],
  },
  password: {
    label: 'labl.password',
    type: FieldType.PASSWORD,
    validator: [
      Validators.required,
      Validators.pattern(ValidationPatterns.noSpaces),
      CustomValidators.changeKey('password_pattern', Validators.pattern(ValidationPatterns.password)),
      CustomValidators.changeKey('symbols_limit', Validators.maxLength(128)),
    ],
  },
  firstName: {
    label: 't.first-name',
    type: FieldType.TEXT,
    validator: [Validators.required, Validators.maxLength(35), Validators.minLength(2)],
  },
  lastName: {
    label: 't.last-name',
    type: FieldType.TEXT,
    validator: [Validators.required, Validators.maxLength(35), Validators.minLength(2)],
  },
};
