export const LoyaltyGroups = [
  {
    name: 'Beginner Panda',
    BOidentifier: 'beginner_panda',
    ids: ['ID1', 'ID18', 'ID19', 'ID20', 'ID21', 'ID22', 'ID23', 'ID24', 'ID25', 'ID26', 'ID27'],
  },
  {
    name: 'Mini Panda',
    BOidentifier: 'mini_panda',
    ids: ['ID2', 'ID28', 'ID29', 'ID30', 'ID31', 'ID32', 'ID33', 'ID34'],
  },
  {
    name: 'Explorer Panda',
    BOidentifier: 'explorer_panda',
    ids: ['ID3', 'ID35', 'ID36', 'ID37', 'ID38', 'ID39'],
  },
  {
    name: 'Braveheart Panda',
    BOidentifier: 'brave_panda',
    ids: ['ID4', 'ID40', 'ID41', 'ID42', 'ID43', 'ID44', 'ID45', 'ID46'],
  },
  {
    name: 'Shadow Panda',
    BOidentifier: 'shadow_panda',
    ids: ['ID5', 'ID47', 'ID48', 'ID49', 'ID50', 'ID51', 'ID52', 'ID53', 'ID54', 'ID55'],
  },
  {
    name: 'Warrior Panda',
    BOidentifier: 'warrior_panda',
    ids: [
      'ID6', 'ID56', 'ID57', 'ID58', 'ID59', 'ID60', 'ID61',
      'ID62', 'ID63', 'ID64', 'ID65', 'ID66', 'ID67', 'ID68',
    ],
  },
  {
    name: 'Mischief Panda',
    BOidentifier: 'mischief_panda',
    ids: [
      'ID7', 'ID69', 'ID70', 'ID71', 'ID72', 'ID73', 'ID74', 'ID75',
      'ID76', 'ID77', 'ID78', 'ID79', 'ID80', 'ID81', 'ID82',
    ],
  },
  {
    name: 'Thunder Panda',
    BOidentifier: 'thunder_panda',
    ids: [
      'ID8', 'ID83', 'ID84', 'ID85', 'ID86', 'ID87', 'ID88', 'ID89', 'ID90',
      'ID91', 'ID92', 'ID93', 'ID94', 'ID95',
    ],
  },
  {
    name: 'Wise Panda',
    BOidentifier: 'wise_panda',
    ids: [
      'ID9', 'ID96', 'ID97', 'ID98', 'ID99', 'ID100', 'ID101', 'ID102', 'ID103',
      'ID104', 'ID105', 'ID106', 'ID107', 'ID108',
    ],
  },
  {
    name: 'Mystic Panda',
    BOidentifier: 'mystic_panda',
    ids: [
      'ID10', 'ID109', 'ID110', 'ID111', 'ID112', 'ID113', 'ID114', 'ID115', 'ID116',
      'ID117', 'ID118', 'ID119', 'ID120', 'ID121', 'ID122',
    ],
  },
  {
    name: 'Golden Panda',
    BOidentifier: 'golden_panda',
    ids: [
      'ID11', 'ID123', 'ID124', 'ID125', 'ID126', 'ID127', 'ID128', 'ID129', 'ID130',
      'ID131', 'ID132', 'ID133', 'ID134',
    ],
  },
  {
    name: 'Legendary Panda',
    BOidentifier: 'legendary_panda',
    ids: [
      'ID12', 'ID135', 'ID136', 'ID137', 'ID138', 'ID139', 'ID140', 'ID141', 'ID142',
      'ID143', 'ID144', 'ID145', 'ID146', 'ID147', 'ID148',
    ],
  },
  {
    name: 'Majestic Panda',
    BOidentifier: 'majestic_panda',
    ids: [
      'ID13', 'ID149', 'ID150', 'ID151', 'ID152', 'ID153', 'ID154', 'ID155', 'ID156',
      'ID157', 'ID158', 'ID159', 'ID160', 'ID161', 'ID162',
    ],
  },
  {
    name: 'Master Panda',
    BOidentifier: 'master_panda',
    ids: [
      'ID14', 'ID163', 'ID164', 'ID165', 'ID166', 'ID167', 'ID168', 'ID169', 'ID170',
      'ID171', 'ID172', 'ID173', 'ID174', 'ID175', 'ID176', 'ID177', 'ID178',
      'ID179', 'ID180', 'ID181', 'ID182', 'ID183', 'ID184', 'ID185', 'ID186',
      'ID187', 'ID188', 'ID189', 'ID190', 'ID191', 'ID192', 'ID193', 'ID194',
    ],
  },
];
