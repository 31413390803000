import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PlatformService } from 'ngx-unificator/services';
import { filter, tap } from 'rxjs/operators';
import { UserService } from './user/user.service';

export interface HistoryRoute {
  /**
   * Route url
   */
  url: string;

  /**
   * Scroll top position for current route
   */
  scrollTop: number;

  /**
   * Additional data for route
   */
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class LocalHistoryService {

  /**
   * Routes in history
   */
  private _routes: Map<string, HistoryRoute> = new Map();

  /**
   * Navigation history
   */
  private _history: Array<string> = [];

  /**
   * Resctricted urls for checking
   */

  private readonly RESTRICTED_URLs: string[] = ['account'];

  constructor(
    private _router: Router,
    private _platform: PlatformService,
    private _user: UserService,
  ) {
    this._handleRouterEvents();
  }

  /**
   * Access to history
   */
  get history(): Array<string> {
    return this._history;
  }

  /**
   * Access to routes
   */
  get routes() {
    return this._routes;
  }

  /**
   * Add URL to history
   *
   * @param key
   * @param url
   * @param data
   */
  addUrl(key: string, url: string, data?: any) {
    this._routes.set(key, {
      url,
      scrollTop: this._platform.isBrowser ? window.scrollY : 0,
      data,
    });
  }

  /**
   * Get URL from history by URL
   *
   * @param key
   */
  getUrl(key: string): HistoryRoute | null {
    if (this._routes.has(key)) {
      return this._routes.get(key);
    }

    return null;
  }

  /**
   * Remove URL from history by key
   *
   * @param key
   */
  removeUrl(key: string) {
    if (this._routes.has(key)) {
      return this._routes.delete(key);
    }
  }

  /**
   * Redirect to provided url not adding current url to browser history
   *
   * @param url
   */
  skipHistoryNavigate(url: string) {
    this._router.navigateByUrl(url, {
      replaceUrl: true,
    }).then();
  }

  /**
   *
   * @param key
   * @param removeFromHistory
   */
  restoreScrollByKey(key: string, removeFromHistory: boolean = true) {
    if (this._routes.has(key) && this._platform.isBrowser) {
      const url = this.getUrl(key);
      window.scroll({
        top: url.scrollTop,
        left: 0,
        behavior: 'smooth',
      });
    }

    if (removeFromHistory) {
      this.removeUrl(key);
    }
  }

  /**
   * Handle router events for saving history
   *
   * @private
   */
  private _handleRouterEvents() {
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap(({ urlAfterRedirects }: NavigationEnd) => this._history.push(urlAfterRedirects)),
    ).subscribe();
  }

  /**
   * Is restricted url
   * @param url
   */

  public isRestrictedUrl(url: string): boolean {
    return Boolean(url && this.RESTRICTED_URLs.find(str => url.includes(str))) && !this._user.auth;
  }

  /**
   * Redirect to previous url
   */
  public redirectToPreviousUrl() {
    const url = this._history[this._history.length - 1];
    if (url) {
      this._router.navigateByUrl(url).then();
    }
  }
}
